<template>
    <div class="card p-2" v-loading="isLoading">
        <div class="text-left d-flex flex-column flex-md-row align-items-center align-items-md-center">
            <div class="pl-0 pl-md-2 flex-fill mb-2 mb-md-0">
                <div class="d-flex flex-column align-items-center align-items-md-start">
                    <h4 class="mb-1"><strong>{{title}}</strong></h4>
                    <h5 class="mb-0"><i class="el-icon-date text-primary"></i> {{filterDateMonth}}</h5>
                </div>
            </div>
            <div class="pr-2">
                <div class="mt-0">
                    <el-radio-group v-model="displayType" size="small" class="mb-0 mr-2 pb-0">
                        <el-radio-button class="mb-0" label="TOTAL_GB_OUT">TOTAL GB OUT</el-radio-button>
                        <el-radio-button class="mb-0" label="ESTIMATED_COST">ESTIMATED COST</el-radio-button>
                    </el-radio-group>
                    <el-button type="primary" size="small"
                               @click="thisMonth()">
                        <i class="el-icon-date"></i> This Month
                    </el-button>
                    <el-button type="primary" size="small"
                               @click="addDate(-1)">
                        <i class="el-icon-arrow-left"></i> Prev
                    </el-button>
                    <el-date-picker
                        v-model="filterDateMonth"
                        type="month"
                        size="small"
                        style="width: 10rem;"
                        class="mr-2 ml-2" 
                        placeholder="Pick a month">
                    </el-date-picker>
                    <el-button type="primary" size="small"
                               @click="addDate(1)"
                               :disabled="currentMonth === filterDateMonth">
                        Next <i class="el-icon-arrow-right"></i>
                    </el-button>

                    <el-button type="primary" size="small" 
                        @click="getData">
                        <i class="el-icon-check"></i> Execute
                    </el-button>
                </div>
            </div>
        </div>
        <highcharts class="mt-2 mr-1" :options="chartOptions.options"></highcharts>
    </div>
</template>

<script>
    import chartDataApi from '@/api/common/chartData';
    import { debounce } from 'vue-debounce';
    import { Chart } from 'highcharts-vue';
    import {
        barChartHoz
    } from "@/constant/dashboardChartOptions";
    import moment from 'moment';
    let initDate = moment();
    export default {
        components: {
            highcharts: Chart,
        },
        props: {
            title: {
                type: String,
                default: "",
            }
        },
        watch: {
            filterDateMonth(newValue) {
                this.getData();
            },
            displayType(newValue) {
                this.isLoading = true;
                this.updateChart();
                this.$nextTick(() => {                    
                    this.isLoading = false;
                });
            }
        },

        data() {
            return {
                debounceFnc: debounce(() => {
                    this.getData();
                }, 300),
                isLoading: false,
                listData: [],
                currentMonth: initDate.format("YYYY-MM"),
                filterDateMonth: initDate.format("YYYY-MM"),
                filterDate: { month: initDate.month(), year: initDate.year() },
                displayType: 'TOTAL_GB_OUT',
                chartOptions: {
                    options: Object.assign(JSON.parse(JSON.stringify(barChartHoz)), {
                        chart: {
                            height: 600,
                            type: 'bar'
                        },
                        legend: { enabled: false },
                        xAxis: {
                            categories: [],
                            title: {
                                text: null
                            },
                            labels: {
                                useHTML: true,
                                animate: true,
                                formatter: function () {
                                    var value = this.value;
                                    return value;
                                }
                            }
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: 'Total transfer out (GB)',
                                align: 'high'
                            },
                            labels: {
                                overflow: 'justify'
                            },
                            opposite: true
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                        return `${this.y} GB`;
                                    }
                                }
                            }
                        },
                        tooltip: {
                            valueSuffix: ' GB'
                        },
                        series: [{
                            name: 'Total transfer out',
                            data: [],
                        }]
                    }),
                }
            };
        },
        mounted() {
            this.getData();
            this.debounceFnc = debounce(() => {
                this.getData();
            }, 60000);
        },
        methods: {
            updateChart(){
                switch(this.displayType){
                    case "TOTAL_GB_OUT":
                        this.chartOptions.options.yAxis.title.text = 'Total transfer out (GB)';
                        this.chartOptions.options.tooltip.valueSuffix = ' GB';
                        this.chartOptions.options.tooltip.valuePrefix = '';
                        this.chartOptions.options.plotOptions.bar.dataLabels.formatter = function () { return `${this.y} GB`; };
                        break;
                    case "ESTIMATED_COST":
                        this.chartOptions.options.yAxis.title.text = 'Estimated cost ($)';
                        this.chartOptions.options.tooltip.valueSuffix = '';
                        this.chartOptions.options.tooltip.valuePrefix = '$';
                        this.chartOptions.options.plotOptions.bar.dataLabels.formatter = function () { return `$${this.y}`; };
                        break;
                }
                this.chartOptions.options.xAxis.categories = [];
                this.chartOptions.options.xAxis.categories = this.listData.map(x => x.USER_NAME);
                this.chartOptions.options.series[0].data = this.displayType === 'TOTAL_GB_OUT' ? this.listData.map(x => (x.TOTAL_GB_OUT)) : this.listData.map(x => (x.ESTIMATED_COST));
                this.chartOptions.options.chart.height = this.chartOptions.options.xAxis.categories.length * 25;
            },
            getData() {
                this.isLoading = true;
                console.log('this.filterDateMonth', this.filterDateMonth);
                let filterAsDate = moment(`${this.filterDateMonth}-01`, "YYYY-MM-DD");
                this.filterDate = { month: filterAsDate.month()+1, year: filterAsDate.year() };
                chartDataApi.getTransferOutS3TopCostByMonth(this.filterDate.month, this.filterDate.year).then(response => {
                    if (response.data && response.data.result === 0 
                        && response.data.data !== null) {
                        this.listData = response.data.data;
                        this.updateChart();
                        this.isLoading = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            this.$message({
                                message: response.data.message || this.$lang.common.error,
                                type: 'warning'
                            });
                        }
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });
            },
            addDate(offsetDay) {
                let filterAsDate = moment(`${this.filterDateMonth}-01`, "YYYY-MM-DD").add(offsetDay, 'months');
                this.filterDateMonth = filterAsDate.format("YYYY-MM");
                //this.getData();
            },
            thisMonth(){
                let filterAsDate = moment();
                this.filterDateMonth = filterAsDate.format("YYYY-MM");
            }
        }
    }
</script>